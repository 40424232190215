import React from 'react';
import './Projects.css';
import { Link } from 'react-router-dom';
import Card from '../components/Card';


function Projects () {
    return (
        <>
         <div className="hero-container">
     <div className="cards-projects">
     <div data-aos="fade-up">
     <h6 className="hero-container-h6">PORTFOLIO PROJECTS</h6>
      <p className="project-title">All Case studies</p>
      <p className="project-info-text">On this page, I post my latest projects in total. Some projects are added out after the release of the products because an NDA was signed. In my work, I not only do the visual part of projects but also very profoundly study the business model itself, character, and ambitions. I also do deep UX research before working on prototypes and first interactions. I create prototypes, add life to them using logic, and fill my projects with realistic content that will resonate in users' minds. Then, I make an external shell that complements all logical chains and allows users to get the most friendly experience using applications, web interfaces, and CRM systems.</p>
                        </div>
        <div className="CardGroup">
        <div data-aos="fade-up">
        <Link className="Linktoproject" to="./Liveshop">
        <Card
          title="Product design for LiveShop, streaming platform"
          text="2023"
          text2="View This Project &#8594;"
          image={require('../images/projects/Liveshop.jpg')} />
        </Link>
        </div>
        <div data-aos="fade-up">
        <Link className="Linktoproject" to="./Snailmates">
        <Card
          title="UX/UI — design for SnailMates, travel application"
          text="2020"
          text2="View This Project &#8594;"
          image={require('../images/projects/Snailmates.jpg')} />
        </Link>
        </div>
        <div data-aos="fade-up">
        <Link className="Linktoproject" to="./Spaceonline">
        <Card 
          title="UX/UI — design for Space Online, Web coaching platform"
          text="2020"
          text2="View This Project &#8594;"
          image={require('../images/projects/Spaceonline.jpg')} />
        </Link>
        </div>
        <div data-aos="fade-up">
        <Link className="Linktoproject" to="./Electroneek">
        <Card 
          title="UX/UI — design for Electroneek, RPA platform"
          text="2020"
          text2="View This Project &#8594;"
          image={require('../images/projects/Electroneek.jpg')} />
          </Link>
          </div>
          <div data-aos="fade-up">
           <Link className="Linktoproject" to="./Gestorsolar">
        <Card 
          title="UX/UI — design for Gestorsolar, logic platform for sun batteries"
          text="2020"
          text2="View This Project &#8594;"
          image={require('../images/projects/Gestrosolar.jpg')} />
        </Link>
        </div>
        <div data-aos="fade-up">
        <Link className="Linktoproject" to="./Sellfashion">
        <Card 
          title="UX/UI — design for SellFashion, online store of clothes"
          text="2019-2020"
          text2="View This Project &#8594;"
          image={require('../images/projects/Clothes.jpg')} />
          </Link>
          </div>
      
          <div data-aos="fade-up">
          <Link className="Linktoproject" to="./Turnkey">
          <Card 
          title="UX/UI — design for TurnKey, startup"
          text="2019-2020"
          text2="View This Project &#8594;"
          image={require('../images/projects/Turnkey.jpg')} />
          </Link>
          </div>
          <div data-aos="fade-up">
          <Link className="Linktoproject" to="./Genieai">
        <Card 
          title="UX/UI — design for Genie AI, contract intelligence"
          text="2019-2020"
          text2="View This Project &#8594;"
          image={require('../images/projects/Genieai.jpg')} />
          </Link>
          </div>
          </div>
      </div>
      </div>
        </>
    );
}

export default Projects;