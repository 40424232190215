import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    return(
        <div className="main-footer">
             <div className="container">
                    <div className="row">
                         {/* Column 1 */}
                         <div className="col-1">
                         <h4 className="row-h4-4">Contact information —</h4>
                        <ul className="list-unstyled-1-1">
                         <li className="footer-text">Feel free to reach out to me any time. I prefer to talk over
email, especially since we may be a few time zones away.</li>
                        <li className="footer-text">&nbsp;</li>
                        <li className="contact-1"><span className="span1">E:&nbsp;</span>myzikano@gmail.com</li>
                        <li className="contact-1"><span className="span1">P:&nbsp;</span>+48 (452)-091-679</li>
                      </ul>
                    </div>
                    {/* Column 2 */}
                    <div className="col-2">
                    <h4 className="row-h4-4">Latest projects —</h4>
                        <ul className="list-unstyled-2-2">
                        
                        <li>
                        <Link className="footer-link" to="./LiveShop">
                        LiveShop
                        </Link>
                        </li>

                        <li>
                        <Link className="footer-link" to="./SnailMates">
                        SnailMates
                        </Link>
                        </li>

                        <li>
                        <Link className="footer-link" to="./Spaceonline">
                        Space Online
                        </Link>
                        </li>

                        <li>
                        <Link className="footer-link" to="./Electroneek">
                        Electroneek
                        </Link>
                        </li>

                        <li>
                        <Link className="footer-link" to="./Gestrosolar">
                        Gestrosolar
                        </Link>
                        </li>
{/*
                        <li>
                        <Link className="footer-link" to="./Sellfashion">
                        SellFashion
                        </Link>
                        </li>
                        
                        <li>
                        <Link className="footer-link" to="./Turnkey">
                        Turn Key
                        </Link>
                        </li>
*/}
                        <li>
                        <Link className="footer-link" to="./projects">
                        All projects
                        </Link>
                        </li>

                      </ul>
                    </div>
                    {/* Column 3 */}
                    <div className="col-3">
                    <h4 className="row-h4-4">Current availability —</h4>
                        <ul className="list-unstyled-3-3">
                         <li className="footer-text">I usually work on several
projects but i’ll be happy to
discuss new opportunities.
Let’s get in touch!</li>
                      </ul>
                    </div>
                    {/* Column 4 */}
                    <div className="col-4">
                    <h4 className="row-h4-4">Follow me&nbsp;on&nbsp;—</h4>
                        <ul className="list-unstyled-4-4">
                        <li><a className="footer-link" href="https://dribbble.com/Myzikano" target="_blank" rel="noopener noreferrer">Dribbble</a></li>
                        <li><a className="footer-link" href="https://www.instagram.com/myzikano/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
                        <li><a className="footer-link" href="https://www.behance.net/Myzikano" target="_blank" rel="noopener noreferrer">Behance</a></li>
                        <li><a className="footer-link" href="https://www.linkedin.com/in/dmytro-kulykov-916912181/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                      </ul>
                       
                    </div>
                </div>
                {/* <hr /> */}
                <div className="row2">
 <table>
 <tbody>
   <tr>
    <td className="col1"><img src={require('../images/logofuter.svg')} width="40" alt="sf" /></td>
    <td className="col2">
    <p className="col-sm">&copy;{new Date().getFullYear()} Dmytro Kulykov.<br></br>
Made with &hearts;         in Kiev, Ukraine.
 </p>
    </td>
   </tr>
   </tbody>
  </table>
</div>    
            </div>
        </div>

    )
}

export default Footer;
