import React from 'react';
import './Aboutme.css';

function Aboutme () {
    return (
        <>
        
      <div className="hero-container-aboutme">
      <div className="aboutme-texts">
      <p data-aos="fade-up" className="aboutme-brow" >A FEW WORDS ABOUT ME</p>
      <p data-aos="fade-up" className="aboutme-title" >I’m Dmytro Kulykov, a multidisciplinary designer who tells my clients’ stories visually through enjoyable and meaningful experiences. I specialize in enterprise products, CRMs, mobile apps, and functional user interfaces.</p>
      <p data-aos="fade-up" className="aboutme-info-text">Over the past 5 years, as a Product designer, 
        I’ve worked with companies and startups to successfully created new apps 
        and web interfaces which reach their full potential.</p>
        <div className="aboutme-table">
        <div data-aos="fade-up" className="text-left-table">
        <p className="text-table-title">DESIGN SERVICES</p>
        
        <p className="text-table">Art direction</p>
        <p className="text-table">Web & mobil</p>
        <p className="text-table">Brand identity</p>
        <p className="text-table">Product design</p>
        <p className="text-table">Iconograpgy</p>
        <p className="text-table">Animation</p>
        <p className="text-table">UX/UI</p>
        </div>

        <img data-aos="fade-up" className="profile-img" src={require('../images/Aboutme/aboutme-me.jpg')} alt="Dmitriy Kulikov" />

        <div data-aos="fade-up" className="icons-right-table">
        <p className="text-table-title">TOOLS I USE</p>
        <div className="icons-table">
        <img className="icons-image" src={require('../images/Aboutme/logo-sketch.png')} width="60px" alt="Sketch" />
        <img className="icons-image" src={require('../images/Aboutme/logo-figma.svg')} width="58px" alt="Figma" />
        <img className="icons-image" src={require('../images/Aboutme/Adobe_Xd.svg')} width="60px" alt="Adobe XD" />
        <img className="icons-image" src={require('../images/Aboutme/logo-photoshop.svg')} width="60px" alt="Photoshop" />
        <img className="icons-image" src={require('../images/Aboutme/logo-illustrator.svg')} width="60px" alt="Illustrator" />
        <img className="icons-image" src={require('../images/Aboutme/Adobe_Lr.svg')} width="60px" alt="Light Room" />
        <img className="icons-image" src={require('../images/Aboutme/Adobe_Ae.svg')} width="60px" alt="After Effect" />
        <img className="icons-image" src={require('../images/Aboutme/Adobe_Pr.svg')} width="60px" alt="Premier Pro" />
        <img className="icons-image" src={require('../images/Aboutme/FinacCut.png')} width="60px" alt="Final CUt" />
        </div>
        </div>
        </div>
        <div data-aos="fade-up" className="Wrapper1">
        <h2 className='bobo'>NEED A DESIGNER?</h2>
        <div className="large">
        <a href="Contact" class="internal-with-icon">Let’s work together&nbsp;<img className="arrowlink" alt="arrow" src={require('../images/arrowlink.svg')} width="50" /></a>
        </div>
      </div>
      </div>
      </div>
       
        </>
    );
}

export default Aboutme;